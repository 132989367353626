import {
    AppBar,
    Avatar, BottomNavigation, BottomNavigationAction, Button,
    Container,
    CssBaseline,
    Grid, Link, ListItem, ListItemText, Paper,
    ThemeProvider, Toolbar,
} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useWeb3Modal from "./hooks/useWeb3Modal";
import Web3 from "web3";
import {
    resetWallet,
    updateWalletAddress,
    updateWalletChain,
    updateWalletProviderType
} from "./redux/slices/coineusSlice";
import getPairPrices from "./utils/get_prices";
import getUserBalances from "./utils/get_balances";
import theme from "./theme";
import {HashRouter as Router, Link as RouterLink, Route, Routes} from "react-router-dom";
import Swap from "./views/Swap";
import LightningRaffles from "./views/Raffles/LightningRaffles";
import LightningRaffleGame from "./views/Raffles/LightningRaffleGame";

import logo from "./images/logo192.png";

import BoltIcon from '@mui/icons-material/Bolt';
import InsightsIcon from '@mui/icons-material/Insights';
import Stats from "./views/Stats";

function LightningStrikes() {

    const dispatch = useDispatch();
    const {wallet} = useSelector(state => state.coineus);

    const [navTab, setNavTab] = useState(0);

    const [web3Modal, setWeb3Modal] = useState(null);
    const web3ModalProvider = useWeb3Modal();

    useEffect(() => {
        if (window.ethereum) {
            connectProvider(window.ethereum)
        }
        changeWeb3Modal();
    }, []);

    async function changeWeb3Modal() {
        const web3mod = await web3ModalProvider.getWeb3Modal();
        if (web3mod.providerController.cachedProvider) {
            const provider = await web3mod.connect()
            await connectProvider(provider)
        }
        setWeb3Modal(web3mod);
    }

    async function connectWallet() {
        const provider = await web3Modal.connect();
        await connectProvider(provider)
    }

    const connectProvider = async (provider) => {
        window.provider = provider
        const web3 = new Web3(provider);
        subscribeProvider(provider)
        const accounts = await web3.eth.getAccounts();
        if (accounts && accounts.length) {
            //set wallet address
            dispatch(updateWalletAddress(accounts[0]))
        }
        const chainId = parseInt(await web3.eth.net.getId())
        dispatch(updateWalletChain(chainId));
        dispatch(updateWalletProviderType(!!provider.bridge ? 'walletconnect' : 'injected'))
    }

    const subscribeProvider = (provider) => {
        provider.on("close", () => {
            clearWallet();
        });

        provider.on("accountsChanged", async (acc) => {
            if (window.provider) {
                dispatch(updateWalletAddress(acc[0]))
            }
        });

        provider.on("chainChanged", async (networkId) => {
            if (window.provider) {
                const chainId = parseInt(networkId)
                dispatch(updateWalletChain(chainId));
            }
        });
    }

    const clearWallet = async () => {
        const web3 = new Web3(window.provider);
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        dispatch(resetWallet())
        await web3Modal.clearCachedProvider();
        window.provider = null;
    }

    //updates pricing
    useEffect(() => {
        getPairPrices();
        const interval = setInterval(() => getPairPrices(), 60000)
        return () => clearInterval(interval);
    }, [wallet.address]);

    //updates balances
    useEffect(() => {
        getUserBalances(wallet.address);
        const interval = setInterval(() => getUserBalances(wallet.address), 60000)
        return () => clearInterval(interval);
    }, [wallet.address]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container maxWidth={'xs'} style={{
                height: '100%'
            }}>

                <Router>
                    <AppBar>
                        <Toolbar>
                            <RouterLink
                                to={`/`}
                                style={{textDecoration: 'none', display: 'flex'}}
                            >
                                <Avatar src={logo} height={40}/>
                            </RouterLink>
                            <ListItem style={{width:200}}>
                                <ListItemText
                                    primary="Lightning Strikes"
                                    secondary={<Link href="https://coineus.app" target="_blank" style={{ textDecoration:'none'}}>A Coineus Project</Link>}
                                    primaryTypographyProps={{style:{fontSize: 18, fontFamily: "'Caesar Dressing', cursive"}}}
                                    secondaryTypographyProps={{style:{fontSize:11}}}
                                />
                            </ListItem>
                                <div style={{flexGrow: 1}}/>
                                {
                                    wallet.address ?
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                clearWallet();
                                            }}
                                        >{getChainLogo(wallet.chain)} 0X...{wallet.address?.substr(-6)}</Button>
                                        :
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => connectWallet()}>Connect</Button>
                                }
                        </Toolbar>
                    </AppBar>
                    <Grid container style={{marginTop: 85, paddingBottom: 10}}>
                        <Grid item xs={12}>
                            <Routes>
                                <Route
                                    path={`/`}
                                    exact={true}
                                    element={<LightningRaffles/>}
                                />
                                <Route
                                    path={`/swap`}
                                    exact={true}
                                    element={<Swap/>}
                                />
                                <Route
                                    path={`/raffles`}
                                    exact={true}
                                    element={<LightningRaffles/>}
                                />
                                <Route
                                    path={`/raffles/:network/:contract_address`}
                                    exact={true}
                                    element={<LightningRaffleGame/>}
                                />
                                <Route
                                    path={`/stats`}
                                    exact={true}
                                    element={<Stats/>}
                                />
                            </Routes>
                        </Grid>
                    </Grid>

                    <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>

                        <BottomNavigation
                            showLabels
                            value={navTab}
                            onChange={(event, newValue) => {
                                setNavTab(newValue);
                            }}
                        >
                            <BottomNavigationAction label="Home" icon={<BoltIcon/>} to={`/`} component={RouterLink}/>
                            <BottomNavigationAction label="Stats" icon={<InsightsIcon/>} to={`/stats`} component={RouterLink}/>
                        </BottomNavigation>
                    </Paper>

                </Router>

            </Container>
        </ThemeProvider>
    );
}

export default LightningStrikes;

function getChainLogo(id) {
    const size = {width: 16, height: 16}
    const style = {marginRight: 10}
    switch (id) {
        case 1:
            return <Avatar src='https://coineus.app/assets/tokens/eth/logo.png' sx={size} style={style}/>
        case 56:
            return <Avatar src='https://coineus.app/assets/tokens/bnb/logo.png' sx={size} style={style}/>
        case 122:
            return <Avatar src='https://coineus.app/assets/tokens/fuse/logo.png' sx={size} style={style}/>
        case 43114:
            return <Avatar src='https://coineus.app/assets/tokens/avax/logo.png' sx={size} style={style}/>
        default:
            return ''
    }
}