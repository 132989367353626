export const makeBatchRequest = (web3, calls) => {
    let batch = new web3.BatchRequest();

    let promises = calls.map(call => {
        return new Promise((res, rej) => {

            if(call instanceof Promise){
                return call.then(resp=>res(resp)).catch(err=>rej(err));
            }

            let req = call.request({}, (err, data) => {
                if(err) rej(err);
                else res(data)
            });
            batch.add(req)
        })
    })
    batch.execute()

    return Promise.all(promises);
}

