import {Container, Grid, Tab, Tabs} from "@mui/material";
import BurnSupply from "../components/BurntSupply";

import Web3 from "web3";
import coineus_abi from "../abis/coineus.json";
import {makeBatchRequest} from "../utils/promisify";
import {useEffect, useState} from "react";

import '../../node_modules/react-vis/dist/style.css';

const web3 = new Web3("https://bsc-dataseed1.binance.org/");
const lit_contract = new web3.eth.Contract(coineus_abi, "0xbC8F89861737C3B8c60a05165E0a5C5B8A137fAc");

const calls = [
    lit_contract.methods.totalSupply().call
];


const web3FUSE = new Web3("https://rpc.fuse.io/");
const lit_fuse_contract = new web3FUSE.eth.Contract(coineus_abi, "0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F");

const callsFUSE = [
    lit_fuse_contract.methods.totalSupply().call
];

export default function Stats() {

    const [supply, setSupply] = useState(0);
    const [burned, setBurned] = useState(0);
    const [chainTab, setChainTab] = useState('fuse');

    const [supplyFuse, setSupplyFuse] = useState(0);
    const [burnedFuse, setBurnedFuse] = useState(0);

    useEffect(() => {
        makeBatchRequest(web3, calls).then(resp => {
            let [
                bbks_supply
            ] = resp;

            setSupply(10000000);
            setBurned(10000000 - (bbks_supply / (10 ** 9)));
        })
    }, [])

    useEffect(() => {
        makeBatchRequest(web3FUSE, callsFUSE).then(resp => {
            let [
                bbks_supply
            ] = resp;

            setSupplyFuse(10000000);
            setBurnedFuse(10000000 - (bbks_supply / (10 ** 18)));
        })
    }, [])

    return (
        <Container maxWidth="sm">
            <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
                <Grid item xs={12}>
                    <Tabs
                        variant="fullWidth"
                        value={chainTab}
                        onChange={(ev, val) => {
                            setChainTab(val)
                        }}
                    >
                        <Tab id="fuse" value="fuse" label="FUSE"/>
                        <Tab id="bnb" value="bnb" label="BNB"/>
                    </Tabs>
                </Grid>


                {
                    chainTab === 'fuse' && <>

                        <Grid item xs={12}>
                            <BurnSupply
                                supply={supplyFuse}
                                burned={burnedFuse}
                            />
                        </Grid>
                    </>
                }
                {
                    chainTab === 'bnb' && <>{/*<Grid item xs={12}>*/}
                        {/*    <Paper style={{padding: 15}}>*/}
                        {/*        <FlexibleWidthXYPlot*/}
                        {/*            height={150}*/}
                        {/*            strokeWidth={3}*/}
                        {/*            margin={{left: 0, right: 0, top: 0, bottom: 0}}*/}
                        {/*            onMouseLeave={onMouseLeave}*/}
                        {/*        >*/}
                        {/*            <LineSeries color="#DADADA" fill="transparent" data={chartData} onNearestX={onNearestX}/>*/}
                        {/*            <Crosshair*/}
                        {/*                values={crosshairValues}*/}
                        {/*                className={'test-class-name'}*/}
                        {/*                style={{*/}
                        {/*                    line:{background: "#fff"}*/}
                        {/*                }}*/}
                        {/*                itemsFormat={(values) => {*/}
                        {/*                    return values.map((v, i) => {*/}
                        {/*                        return {*/}
                        {/*                            title: "Price",*/}
                        {/*                            value: v.y*/}
                        {/*                        };*/}
                        {/*                    });*/}
                        {/*                }}*/}
                        {/*                titleFormat={(values) => {*/}
                        {/*                    return {*/}
                        {/*                        title: "Date",*/}
                        {/*                        value: values[0].x.toLocaleDateString()*/}
                        {/*                    };*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*        </FlexibleWidthXYPlot>*/}
                        {/*    </Paper>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <BurnSupply
                                supply={supply}
                                burned={burned}
                            />
                        </Grid>
                    </>
                }


            </Grid>
        </Container>
    )

}