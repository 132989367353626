import store from "../redux/store";
import Web3 from "web3";
import {makeBatchRequest} from "./promisify";
import {updateBalances, updateLPInfo, updateStaked, updateTotals} from "../redux/slices/coineusSlice";
import coineus_abi from "../abis/coineus.json";
import consensus_abi from "../abis/consensus_abi.json";
import uni_pair_abi from "../abis/IUniswapV2Pair.json";

export default function getUserBalances(wallet_address) {
    const _store = store.getState();
    const {pairs, rpc} = _store.coineus;

    if (wallet_address === undefined) {
        return;
    }

    getFUSE(pairs, rpc.fuse, wallet_address);
    getAVAX(pairs, rpc.avax, wallet_address);
    getBNB(pairs, rpc.bnb, wallet_address);
    getETH(pairs, rpc.eth, wallet_address);
}

function getFUSE(pairs, rpc, wallet_address) {

    const web3 = new Web3(rpc);
    const ceus_contract = new web3.eth.Contract(coineus_abi, "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8");
    const fusd_contract = new web3.eth.Contract(coineus_abi, '0xd0ce1b4A349C35e61Af02f5971e71ac502441E49');
    const volt_contract = new web3.eth.Contract(coineus_abi, '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4');
    const fsb_contract = new web3.eth.Contract(coineus_abi, '0x28344D35203CF225E84f5B75E0d64bA3f2d9cc31');
    const lit_contract = new web3.eth.Contract(coineus_abi, '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F');

    const btc_contract = new web3.eth.Contract(coineus_abi, '0x33284f95ccb7B948d9D352e1439561CF83d8d00d');
    const eth_contract = new web3.eth.Contract(coineus_abi, '0xa722c13135930332Eb3d749B2F0906559D2C5b99');
    const bnb_contract = new web3.eth.Contract(coineus_abi, '0x6acb34b1Df86E254b544189Ec32Cf737e2482058');
    const wfuse_contract = new web3.eth.Contract(coineus_abi, '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629');

    const coineusValidatorAddress = "0x53e2033F86C1a54bEbAE893DC8C9fAe179b3Faa2";
    const consensusAddress = "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79";
    const consensusContract = new web3.eth.Contract(consensus_abi, consensusAddress);

    const ceus_lit_contract = new web3.eth.Contract(uni_pair_abi, "0x87440F829E7ED808599749630555080917c4b3D9") //CEUS/LIT LP
    const ceus_fuse_contract = new web3.eth.Contract(uni_pair_abi, "0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b") //CEUS/FUSE LP
    const fuse_lit_contract = new web3.eth.Contract(uni_pair_abi, "0x1a77baea67c346ce17486b078a3c3f53a99a9fcc") //LIT/FUSE LP

    const calls = [
        web3.eth.getBalance(wallet_address),
        ceus_contract.methods.balanceOf(wallet_address).call,
        fusd_contract.methods.balanceOf(wallet_address).call,
        volt_contract.methods.balanceOf(wallet_address).call,
        consensusContract.methods.delegatedAmount(wallet_address, coineusValidatorAddress).call,
        fsb_contract.methods.balanceOf(wallet_address).call,
        lit_contract.methods.balanceOf(wallet_address).call,

        btc_contract.methods.balanceOf(wallet_address).call,
        eth_contract.methods.balanceOf(wallet_address).call,
        bnb_contract.methods.balanceOf(wallet_address).call,
        wfuse_contract.methods.balanceOf(wallet_address).call,

        ceus_lit_contract.methods.balanceOf(wallet_address).call,
        ceus_lit_contract.methods.getReserves().call,
        ceus_lit_contract.methods.totalSupply().call,

        ceus_fuse_contract.methods.balanceOf(wallet_address).call,
        ceus_fuse_contract.methods.getReserves().call,
        ceus_fuse_contract.methods.totalSupply().call,

        fuse_lit_contract.methods.balanceOf(wallet_address).call,
        fuse_lit_contract.methods.getReserves().call,
        fuse_lit_contract.methods.totalSupply().call,
    ];

    makeBatchRequest(web3, calls).then(resp => {
        let [
            fuse_balance,
            ceus_balance,
            fusd_balance,
            volt_balance,
            staked_fuse_with_coineus,
            fsb_balance,
            lit_balance,

            btc_balance,
            eth_balance,
            bnb_balance,
            wfuse_balance,

            ceus_lit_balance,
            ceus_lit_reserves,
            ceus_lit_totalSupply,

            ceus_wfuse_balance,
            ceus_wfuse_reserves,
            ceus_wfuse_totalSupply,

            fuse_lit_balance,
            fuse_lit_reserves,
            fuse_lit_totalSupply,

        ] = resp;

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: 'native',
            balance: fuse_balance / (10 ** 18)
        }))

        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: 'native',
            staked: staked_fuse_with_coineus / (10 ** 18)
        }))

        //ceus
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8',
            balance: ceus_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xd0ce1b4A349C35e61Af02f5971e71ac502441E49',//fUSD
            balance: fusd_balance / (10 ** 18)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4',//volt
            balance: volt_balance / (10 ** 18)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x28344D35203CF225E84f5B75E0d64bA3f2d9cc31',//fsb
            balance: fsb_balance / (10 ** 9)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F',//lit
            balance: lit_balance / (10 ** 18)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x33284f95ccb7B948d9D352e1439561CF83d8d00d',//btc
            balance: btc_balance / (10 ** 8)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xa722c13135930332Eb3d749B2F0906559D2C5b99',//eth
            balance: eth_balance / (10 ** 18)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x6acb34b1Df86E254b544189Ec32Cf737e2482058',//bnb
            balance: bnb_balance / (10 ** 18)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',//wfuse
            balance: wfuse_balance / (10 ** 18)
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x87440F829E7ED808599749630555080917c4b3D9',//ceus/lit lp
            balance: ceus_lit_balance / (10**18)
        }));

        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x87440F829E7ED808599749630555080917c4b3D9',//ceus/lit lp
            lp: {
                token0: ceus_lit_reserves.reserve0 / (10**18) * ceus_lit_balance/ceus_lit_totalSupply,
                token1: ceus_lit_reserves.reserve1 / (10**18) * ceus_lit_balance/ceus_lit_totalSupply,
                share: ceus_lit_balance/ceus_lit_totalSupply * 100
            }
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',//ceus/wfuse lp
            balance: ceus_wfuse_balance / (10**18)
        }));

        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',//ceus/wfuse lp
            lp: {
                token0: ceus_wfuse_reserves.reserve0 / (10**18) * ceus_wfuse_balance/ceus_wfuse_totalSupply,
                token1: ceus_wfuse_reserves.reserve1 / (10**18) * ceus_wfuse_balance/ceus_wfuse_totalSupply,
                share: ceus_wfuse_balance/ceus_wfuse_totalSupply * 100
            }
        }));

        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x1a77baea67c346ce17486b078a3c3f53a99a9fcc',//fuse/lit lp
            balance: fuse_lit_balance / (10**18)
        }));

        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x1a77baea67c346ce17486b078a3c3f53a99a9fcc',//fuse/lit lp
            lp: {
                token0: fuse_lit_reserves.reserve0 / (10**18) * fuse_lit_balance/fuse_lit_totalSupply,
                token1: fuse_lit_reserves.reserve1 / (10**18) * fuse_lit_balance/fuse_lit_totalSupply,
                share: fuse_lit_balance/fuse_lit_totalSupply * 100
            }
        }));

        //0x1a77baea67c346ce17486b078a3c3f53a99a9fcc

        store.dispatch(updateTotals('fuse'));
    })
}

function getAVAX(pairs, rpc, wallet_address) {

    const web3AVAX = new Web3(rpc);
    const usdc_contract = new web3AVAX.eth.Contract(coineus_abi, '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E');
    const dgnx_contract = new web3AVAX.eth.Contract(coineus_abi, '0x51e48670098173025C477D9AA3f0efF7BF9f7812');

    const callsAVAX = [
        web3AVAX.eth.getBalance(wallet_address),
        usdc_contract.methods.balanceOf(wallet_address).call,
        dgnx_contract.methods.balanceOf(wallet_address).call,
    ];

    makeBatchRequest(web3AVAX, callsAVAX).then(resp => {
        let [
            avax_balance,
            usdc_balance,
            dgnx_balance,
        ] = resp;

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: 'native',
            balance: avax_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', //usdc
            balance: usdc_balance / (10 ** 6)
        }))

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: '0x51e48670098173025C477D9AA3f0efF7BF9f7812', //dgnx
            balance: dgnx_balance / (10 ** 18)
        }))

        store.dispatch(updateTotals('avax'));

    })
        .catch(console.log)
}

function getBNB(pairs, rpc, wallet_address) {
    const web3BSC = new Web3(rpc);
    const feg_contract = new web3BSC.eth.Contract(coineus_abi, '0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11');
    const srg_contract = new web3BSC.eth.Contract(coineus_abi, '0x9f19c8e321bD14345b797d43E01f0eED030F5Bff');
    const busd_contract = new web3BSC.eth.Contract(coineus_abi, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56');
    const usdc_contract = new web3BSC.eth.Contract(coineus_abi, '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d');
    const usdt_contract = new web3BSC.eth.Contract(coineus_abi, '0x55d398326f99059fF775485246999027B3197955');

    const callsBSC = [
        web3BSC.eth.getBalance(wallet_address),
        feg_contract.methods.balanceOf(wallet_address).call,
        srg_contract.methods.balanceOf(wallet_address).call,
        busd_contract.methods.balanceOf(wallet_address).call,
        usdc_contract.methods.balanceOf(wallet_address).call,
        usdt_contract.methods.balanceOf(wallet_address).call
    ];

    makeBatchRequest(web3BSC, callsBSC).then(resp => {
        let [
            bnb_balance,
            feg_balance,
            srg_balance,
            busd_balance,
            usdc_balance,
            usdt_balance
        ] = resp;

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: 'native',
            balance: bnb_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11',//feg
            balance: feg_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0x9f19c8e321bD14345b797d43E01f0eED030F5Bff',//srg
            balance: srg_balance / (10 ** 9)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',//busd
            balance: busd_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',//usdc
            balance: usdc_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0x55d398326f99059fF775485246999027B3197955',//usdt
            balance: usdt_balance / (10 ** 18)
        }))

        store.dispatch(updateTotals('bnb'));

    })
}

function getETH(pairs, rpc, wallet_address) {
    const web3ETH = new Web3(rpc);
    const bbks_contract = new web3ETH.eth.Contract(coineus_abi, '0xF654d4C3CC334324ad474A0d5d3708dCA4c1CB25');
    const srg_contract = new web3ETH.eth.Contract(coineus_abi, '0xcD682EF09d07668d49A8103ddD65Ff54AebFbfDe');
    const feg_contract = new web3ETH.eth.Contract(coineus_abi, '0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11');
    const usdc_contract = new web3ETH.eth.Contract(coineus_abi, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48');
    const usdt_contract = new web3ETH.eth.Contract(coineus_abi, '0xdAC17F958D2ee523a2206206994597C13D831ec7');

    const callsETH = [
        web3ETH.eth.getBalance(wallet_address),
        bbks_contract.methods.balanceOf(wallet_address).call,
        srg_contract.methods.balanceOf(wallet_address).call,
        feg_contract.methods.balanceOf(wallet_address).call,
        usdc_contract.methods.balanceOf(wallet_address).call,
        usdt_contract.methods.balanceOf(wallet_address).call
    ];

    makeBatchRequest(web3ETH, callsETH).then(resp => {
        let [
            eth_balance,
            bbks_balance,
            srg_balance,
            feg_balance,
            usdc_balance,
            usdt_balance
        ] = resp;

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: 'native',
            balance: eth_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xF654d4C3CC334324ad474A0d5d3708dCA4c1CB25',//bbk
            balance: bbks_balance / (10 ** 9)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xcD682EF09d07668d49A8103ddD65Ff54AebFbfDe',//srg
            balance: srg_balance / (10 ** 9)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11',//feg
            balance: feg_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',//usdc
            balance: usdc_balance / (10 ** 6)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',//usdt
            balance: usdt_balance / (10 ** 6)
        }))

        store.dispatch(updateTotals('eth'));

    })
}