import {CircularProgress, Dialog, DialogContent, Typography} from "@mui/material";
import cns_logo from '../images/logo192.png'

export default function CoineusLoading(props) {

    return <Dialog
        open={props.open}
        // PaperProps={{style: {backgroundColor: '#1E3140', color: "#dadada"}}}
    >
        <DialogContent>
            <div style={{margin: '0 auto', textAlign: 'center'}}>
                <img
                    src={cns_logo}
                    alt="Divvy Logo"
                    width={100}
                    style={{position: 'fixed', margin: 10, borderRadius: "50%"}}
                />
                <CircularProgress color="secondary" size={120} thickness={1}/>
            </div>
            <Typography textAlign="center" style={{marginTop: 20}}>{props.label}</Typography>
        </DialogContent>
    </Dialog>
}