import {FormControl, TextField} from "@mui/material";

export default function SwapInput(props) {

    const {label, max, disabled, value, onChange, startAdornment, endAdornment} = props;

    return (
        <FormControl
            style={{
                backgroundColor: 'rgb(0,0,0)', // same as background default
                border: 'none',
                borderColor: 'unset',
                borderRadius: 4
            }}
            fullWidth>
            <TextField
                variant="outlined"
                type="text"
                inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder="0"
                max={max}
                disabled={disabled}
                value={value}
                onChange={onChange}
                label={label}
                InputProps={{
                    inputProps: {
                        style: {
                            textAlign: 'right',
                        }
                    },
                    startAdornment: startAdornment,
                    endAdornment: endAdornment
                }}
            />
        </FormControl>
    )
}
