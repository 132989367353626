import {Paper} from "@mui/material";

export default function Panel(props) {
    return (<Paper style={{
        color: 'white',
        padding: 10,
        margin: 0,
        borderRadius: 4,
        backgroundColor: "#000",
        border: "2px solid #222"
    }}>{props.children}</Paper>)
}