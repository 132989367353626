import {Avatar, Button, Container, Divider, Grid, InputAdornment, Link, Paper, Typography} from "@mui/material";
import SwapInput from "../components/SwapInput";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/styles";
import {useSelector} from "react-redux";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import Web3 from "web3";
import uni_abi from "../abis/IUniswapV2Pair.json";
import router_abi from "../abis/IPancakeRouter.json";
import CoineusLoading from "../components/CoineusLoading";
import getUserBalances from "../utils/get_balances";

const pairAddress = '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b';
const routerAddress = '0xE3F85aAd0c8DD7337427B9dF5d0fB741d65EEEB5';

export default function Swap() {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const theme = useTheme();

    const [amountIn, setAmountIn] = useState(0);
    const [amountOut, setAmountOut] = useState(0);
    const [pending, setPending] = useState(false);

    const [reserves, setReserves] = useState([0, 0]);

    const [amtRecieved, setAmtRecieved] = useState(undefined);
    const [txHash, setTxHash] = useState(undefined);
    const [txCost, setTxCost] = useState(undefined);

    const getCeusAmt = () => {

        if (!amountIn || !reserves[0] || !reserves[1]) {
            setAmountOut(0)
            return;
        }

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(router_abi, routerAddress);

        contract.methods.getAmountOut(web3.utils.toWei(amountIn), reserves[0], reserves[1]).call((err, resp) => {
            setAmountOut(CoineusCryptoFormat(resp / (10 ** 18) * .995));
        })
    }

    useEffect(getCeusAmt, [amountIn, reserves, rpc.fuse]);

    const getReserves = () => {
        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(uni_abi, pairAddress);

        contract.methods.getReserves().call((err, resp) => {
            setReserves([resp.reserve0, resp.reserve1]);
        })
    }

    useEffect(() => {
        getReserves();
        const interval = setInterval(getReserves, 10000)
        return () => clearInterval(interval);
    }, []);

    const swap = async () => {
        setAmtRecieved(undefined);
        setTxHash(undefined);
        setTxCost(undefined);

        const web3 = new Web3(window.provider);
        const contract = new web3.eth.Contract(router_abi, routerAddress);
        const gasPrice = await web3.eth.getGasPrice();

        contract.methods.swapExactETHForTokens(
            web3.utils.toWei((amountOut).toString()),
            [
                '0x0be9e53fd7edac9f859882afdda116645287c629',
                '0x4e69ae0cd024754655b4ef74f24a8dcb39ba07e8'
            ],
            wallet.address,
            (((new Date()).getTime() + 60000) / 1000).toFixed(0)
        )
            .send(
                {
                    from: wallet.address,
                    value: web3.utils.toWei(amountIn),
                    gas: 300000,
                    gasPrice
                }
            )
            .once('transactionHash', function (tx) {
                setPending(true);
                setTxHash(tx);
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                getUserBalances(wallet.address);
                setAmtRecieved(web3.utils.hexToNumberString(receipt.events[2].raw.data) / (10 ** 18));
                setTxCost(receipt.gasUsed * (receipt.effectiveGasPrice / (10 ** 18)));
                setPending(false);
            });
    }

    return (
        <Container maxWidth="sm">
            <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
                <Grid item xs={12}>
                    <Paper style={{padding: 20}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SwapInput
                                    value={amountIn || ""}
                                    onChange={(ev) => {
                                        setAmountIn(ev.target.value)
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    backgroundColor: theme.palette.background.paper,
                                                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                                                    color: "#fff"
                                                }}
                                                startIcon={<Avatar src='https://coineus.app/assets/tokens/fuse/logo.png'
                                                                   style={{width: 24, height: 24}}/>}
                                            >FUSE
                                            </Button>
                                        </InputAdornment>
                                    }
                                />
                                <Typography variant="caption"
                                            style={{float: 'right'}}>Balance: {CoineusCryptoFormat(wallet.balances.fuse.native)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SwapInput
                                    disabled
                                    value={amountOut || ""}
                                    onChange={(ev) => {
                                        setAmountOut(ev.target.value)
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    backgroundColor: theme.palette.background.paper,
                                                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                                                    color: "#fff"
                                                }}
                                                startIcon={<Avatar
                                                    src='https://coineus.app/assets/tokens/fuse/0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8/logo.png'
                                                    style={{width: 24, height: 24}}/>}
                                            >CEUS
                                            </Button>
                                        </InputAdornment>
                                    }
                                />
                                <Typography variant="caption"
                                            style={{float: 'right'}}>Balance: {CoineusCryptoFormat(wallet.balances.fuse["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"])}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" onClick={swap}>SWAP</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    {
                        amtRecieved !== undefined && <>
                            <Divider style={{marginTop: 40, marginBottom: 10}}>RECEIPT</Divider>
                            <Paper
                                style={{
                                    backgroundColor: 'white',
                                    color: "#000",
                                    padding: 20,
                                    fontFamily: 'monospace'
                                }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace'}}>Purchased</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                                            {`${CoineusCryptoFormat(amtRecieved)} CEUS`}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{marginTop: 20}} />


                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace'}}>TxFee</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                                            {`${CoineusCryptoFormat(txCost)} FUSE`}
                                        </Typography>
                                    </Grid>


                                    <Grid item xs={6}/>
                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                                            {`${CoineusUSDFormat(txCost * wallet.prices.fuse?.native?.inUSD)} USD`}
                                        </Typography>
                                    </Grid>


                                    <Grid item xs={12} style={{marginTop: 20}} />

                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace'}}>TxHash</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>

                                            <Link
                                                style={{color: "#000", textDecorationColor: '#000'}}
                                                href={`https://explorer.fuse.io/tx/${txHash}`}
                                                target="_blank">{`0x..${txHash.substr(-12)}`}</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </>
                    }
                </Grid>
            </Grid>
            <CoineusLoading open={pending} label="Transaction Pending"/>
        </Container>
    )
}