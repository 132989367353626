import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Web3 from "web3";
import {
    Button,
    Container,
    Fab,
    Grid,
    List,
    ListItem,
    ListItemText, Slider, TextField,
    Typography
} from "@mui/material";
import {makeBatchRequest} from "../../utils/promisify";
import {CoineusCryptoFormat} from "../../utils/currency_format";
import CoineusLoading from "../../components/CoineusLoading";
import {useParams, Link as RouterLink} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import IERC20 from "../../abis/IERC20.json";
import NATIVE_NATIVE_ABI from "../../abis/LightningRaffles/NativeNative.json";


import {toWei} from "../../utils/format";
import Panel from "../../components/Panel";

export default function LightningRaffleGame() {

    let {contract_address, network} = useParams();

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [pending, setPending] = useState(false);

    const [raffle, setRaffle] = useState({});

    const [allowance, setAllowance] = useState(0);
    const [entrySupply, setEntrySupply] = useState(0);

    const [numTickets, setNumTickets] = useState(1);


    const [_adminFee, _setAdminFee] = useState(undefined);
    const [_jackpotCap, _setJackpotCap] = useState(undefined);
    const [_maxEntries, _setMaxEntries] = useState(undefined);
    const [_nextDrawIntervalHours, _setNextDrawIntervalHours] = useState(undefined);
    const [_sendFeeTo, _setSendFeeTo] = useState(undefined);
    const [_ticketCostEther, _setTicketCostEther] = useState(undefined);
    const [_ticketCostWei, _setTicketCostWei] = useState(undefined);
    const [_fundRounds, _setFundRounds] = useState(0);

    const getRaffleInfo = (contract_address, callback) => {

        if (!wallet.address) return;

        const web3 = new Web3(rpc[network]);

        const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

        const calls = [
            raffle_contract.methods.getRaffleInfo().call,
            raffle_contract.methods.getTokens().call,
            raffle_contract.methods.getPlayers().call,
            raffle_contract.methods.getStatsByAddress(wallet.address).call,
            web3.eth.getBalance(contract_address)
        ];

        makeBatchRequest(web3, calls).then(resp => {
            const [
                raffleInfo,
                tokens,
                players,
                playerStats,
                bal
            ] = resp;

            let jp;
            let token_contract = new web3.eth.Contract(IERC20, tokens.jackpot.id);
            let entry_contract = new web3.eth.Contract(IERC20, tokens.entry.id);

            //raffleType enum
            //{NATIVE_NATIVE, NATIVE_TOKEN_JACKPOT, TOKEN_TOKEN, TOKEN_TOKEN_JACKPOT}
            // eslint-disable-next-line default-case
            switch (parseInt(raffleInfo.raffle_type)) {
                case 0:
                    //use native bal
                    jp = bal - (bal / raffleInfo.admin_fee);
                    break;
                case 1:
                case 3:
                    //use cap
                    jp = raffleInfo.cap;
                    break;
                case 2:
                    jp = undefined;
                    break;
            }

            const calls2 = [
                tokens.jackpot.id === "0x0000000000000000000000000000000000000000" ? web3.eth.getBalance(contract_address) : token_contract.methods.balanceOf(contract_address).call,
                raffle_contract.methods.getGameHistoryByRaffleID(raffleInfo.raffle_id - 1 > 0 ? raffleInfo.raffle_id - 1 : 0).call,
                tokens.entry.id === "0x0000000000000000000000000000000000000000" ? (new Promise((resolve) => {
                    resolve()
                })) : entry_contract.methods.allowance(wallet.address, contract_address).call,
                tokens.entry.id === "0x0000000000000000000000000000000000000000" ? (new Promise((resolve) => {
                    resolve()
                })) : entry_contract.methods.totalSupply().call
            ];

            makeBatchRequest(web3, calls2).then(resp => {
                let [
                    jackpot,
                    history,
                    _allowance,
                    entrySupply
                ] = resp;

                if (jp === undefined) jp = (jackpot - (jackpot / raffleInfo.admin_fee));

                let roundsRemaining = undefined, jackpotTokenBalance;
                if (raffleInfo.raffle_type === "1" || raffleInfo.raffle_type === "3") {
                    jackpotTokenBalance = jackpot;
                    roundsRemaining = jackpot / raffleInfo.cap;
                }

                setAllowance(_allowance / 10 ** tokens.entry.decimals);
                setEntrySupply(entrySupply);

                if (_adminFee === undefined) _setAdminFee(raffleInfo.admin_fee);
                if (_jackpotCap === undefined) _setJackpotCap(raffleInfo.cap);
                if (_maxEntries === undefined) _setMaxEntries(raffleInfo.entries_allowed);
                if (_nextDrawIntervalHours === undefined) _setNextDrawIntervalHours(raffleInfo.next_draw_interval_hours);
                if (_sendFeeTo === undefined) _setSendFeeTo(raffleInfo.send_fee_to);
                if (_ticketCostEther === undefined) _setTicketCostEther(raffleInfo.ticket_cost / (10 ** tokens.entry.decimals));
                if (_ticketCostWei === undefined) _setTicketCostWei(raffleInfo.ticket_cost);

                callback({
                    ticketCost: raffleInfo.ticket_cost / (10 ** tokens.entry.decimals),
                    adminFee: raffleInfo.admin_fee,
                    raffleID: raffleInfo.raffle_id,
                    entriesAllowed: raffleInfo.entries_allowed / 1,
                    nextDraw: raffleInfo.next_draw * 1000,
                    nextDrawInterval: raffleInfo.next_draw_interval_hours,
                    totalFees: raffleInfo.total_fees,
                    totalWinnings: raffleInfo.total_winnings / (10 ** tokens.jackpot.decimals),
                    raffleType: raffleInfo.raffle_type / 1,
                    raffleState: raffleInfo.raffle_state / 1,
                    jackpot: jp / (10 ** tokens.jackpot.decimals),
                    players,
                    previousWinner: history.winner,
                    previousJackpot: history.jackpot,
                    playerStats,
                    tokens,
                    roundsRemaining,
                    jackpotTokenBalance: jackpotTokenBalance / (10 ** tokens.jackpot.decimals)
                });
            });
        })
            .catch(console.log)
    }

    useEffect(() => {
        getRaffleInfo(contract_address, setRaffle);
        const interval = setInterval(() => getRaffleInfo(contract_address, setRaffle), 30000)
        return () => clearInterval(interval);
    }, [rpc[network], wallet.address]);

    const [drawingTime, setDrawingTime] = useState(0);
    useEffect(() => {
        setDrawingTime(msToHMS(timeToNextDraw(raffle.nextDraw)));
        const interval = setInterval(() => {
            setDrawingTime(msToHMS(timeToNextDraw(raffle.nextDraw)));
        }, 1000);
        return () => clearInterval(interval);
    })

    const pickWinner = () => {
        const web3 = new Web3(window.provider);
        const contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);
        contract.methods.pickWinner("coineus" + (new Date()).getTime())
            .send(
                {
                    from: wallet.address
                }
            )
            .then(function (receipt) {
                getRaffleInfo()
            })
            .catch(e => console.log);

    }

    const checkAllowance = (entry_contract, amount) => {
        return new Promise((resolve, reject) => {
            entry_contract
                .methods
                .allowance(wallet.address, contract_address)
                .call((err, resp) => {
                    if ((resp / (10 ** raffle.tokens.entry.decimals)) >= amount) {
                        resolve();
                    } else {
                        reject();
                    }
                })
        });
    }

    const increaseAllowance = (entry_contract, amount) => {
        return new Promise((resolve, reject) => {
            entry_contract
                .methods
                .approve(contract_address, entrySupply.toString())
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    setPending(false)
                    resolve();
                });
        });
    }

    const enterMulti = (entries) => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const entry_contract = new web3.eth.Contract(IERC20, raffle.tokens.entry.id);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            entries = entries || 1;

            const enterRaffle = () => {
                raffle_contract.methods.multiEnter(entries)
                    .send({from: wallet.address})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        getRaffleInfo(contract_address, setRaffle)
                        setPending(false);
                    });
            }

            checkAllowance(entry_contract, raffle.ticketCost * entries)
                .then(enterRaffle)
                .catch(() => {
                    console.log('call increaseAllowance')
                    increaseAllowance(entry_contract).then(enterRaffle)
                })

        }
    }

    const enterNativeMulti = (entries) => {
        setPending(true)
        const web3 = new Web3(window.provider);
        const contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

        contract.methods.multiEnter(entries)
            .send(
                {
                    from: wallet.address,
                    value: web3.utils.toWei((raffle.ticketCost * entries).toString()),
                    gas: 300000,
                    gasPrice: 10000000000
                }
            )
            .once('transactionHash', function (tx) {
                setPending(true);
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                getRaffleInfo(contract_address, setRaffle)
                setPending(false);
            });
    }

    const checkChain = () => {
        switch (network) {
            case "avax":
                if (wallet.chain === 43114) return true;
                break;
            case "bnb":
                if (wallet.chain === 56) return true;
                break;
            case "fuse":
                if (wallet.chain === 122) return true;
                break;
            default:
                return false;
        }
    }

    const changeNetwork = async () => {
        let chainID = 122;
        switch (network) {
            case "avax":
                chainID = 43114;
                break;
            case "bnb":
                chainID = 56;
                break;
            case "fuse":
                chainID = 122;
                break;
            default:
                return false;
        }

        const web3 = new Web3(window.provider);
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: web3.utils.toHex(chainID)}], // chainId must be in hexadecimal numbers
        });
    }

    const getButtonLabel = (entries = 1) => {
        if (raffle.raffleState === 1)
            return "RAFFLE CLOSED";

        if (getOccurrence(raffle.players, wallet.address) >= raffle.entriesAllowed)
            return "MAX ENTRIES";

        if (timeToNextDraw(raffle.nextDraw) < 0)
            return "WAITING TO PICK WINNER";

        let approve = "";

        if (raffle.tokens.entry.id !== "0x0000000000000000000000000000000000000000") {
            if (allowance < raffle.ticketCost) {
                approve = "APPROVE + ";
            }

            if (entries > 1 && allowance < raffle.ticketCost * entries) {
                approve = "APPROVE + ";
            }
        }

        if (entries > 1) {
            return `${approve}BUY ${entries} TICKETS (${raffle.ticketCost * entries} ${raffle.tokens.entry.symbol})`;
        }

        return `${approve}BUY ${entries} TICKET (${raffle.ticketCost} ${raffle.tokens.entry.symbol})`;
    }

    const getButtonDisabled = () => {
        if (raffle.raffleState === 1)
            return true;

        if (getOccurrence(raffle.players, wallet.address) >= raffle.entriesAllowed)
            return true;

        return timeToNextDraw(raffle.nextDraw) < 0;
    }

    if (Object.entries(raffle).length < 1) {
        return <CoineusLoading open={true} label="Loading..."/>;
    }


    // ADMIN FUNCTIONS
    const startRaffle = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.startRaffle()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const openRaffle = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.openRaffle()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const closeRaffle = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.closeRaffle()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const resetNextDraw = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.resetNextDraw()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setAdminFee = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setAdminFee(_adminFee)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setJackpotCap = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setJackpotCap(_jackpotCap)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setMaxEntries = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setMaxEntries(_maxEntries)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setNextDrawIntervalHours = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setNextDrawIntervalHours(_nextDrawIntervalHours)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setSendFeeTo = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setSendFeeTo(_sendFeeTo)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setTicketCostEther = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setTicketCostEther(_ticketCostEther)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const setTicketCostWei = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const raffle_contract = new web3.eth.Contract(NATIVE_NATIVE_ABI, contract_address);

            raffle_contract.methods.setTicketCostWei(_ticketCostWei)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }
    const fundRounds = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(IERC20, raffle.tokens.jackpot.id);

            contract.methods.transfer(contract_address, toWei(_fundRounds * raffle.jackpot, raffle.tokens.jackpot.decimals))
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getRaffleInfo(contract_address, setRaffle)
                    setPending(false);
                });
        }
    }

    return (
        <Container maxWidth="sm">
            <Grid container spacing={4} style={{paddingBottom: 80}}>
                <Grid item xs={12}>
                    <Fab to={"/raffles"} component={RouterLink} size="small"
                         style={{position: 'fixed', top: 8, left: 15, zIndex: 99999}}><ArrowBackIcon/></Fab>
                </Grid>

                {
                    wallet.address === '0xb80267eA7fa368374ee4d4Bf10044778232AdEFe' && <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled={raffle.raffleState === 1 || !checkChain()}
                                        onClick={startRaffle}>Start Raffle</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled={raffle.raffleState === 0 || !checkChain()}
                                        onClick={openRaffle}>Open Raffle</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled={raffle.raffleState === 1 || !checkChain()}
                                        onClick={closeRaffle}>Close Raffle</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled={raffle.raffleState === 1 || !checkChain()}
                                        onClick={resetNextDraw}>Reset Draw</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Admin Fee"
                                        value={_adminFee}
                                        onChange={ev => _setAdminFee(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setAdminFee}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Jackpot Cap"
                                        value={_jackpotCap}
                                        onChange={ev => _setJackpotCap(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setJackpotCap}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Max Entries"
                                        value={_maxEntries}
                                        onChange={ev => _setMaxEntries(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setMaxEntries}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Next Drawing Interval Hours"
                                        value={_nextDrawIntervalHours}
                                        onChange={ev => _setNextDrawIntervalHours(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setNextDrawIntervalHours}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Send Fee To"
                                        value={_sendFeeTo}
                                        onChange={ev => _setSendFeeTo(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setSendFeeTo}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Set Ticket Cost Ether"
                                        value={_ticketCostEther}
                                        onChange={ev => _setTicketCostEther(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setTicketCostEther}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Set Ticket Cost Wei"
                                        value={_ticketCostWei}
                                        onChange={ev => _setTicketCostWei(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={setTicketCostWei}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Fund Rounds"
                                        value={_fundRounds}
                                        onChange={ev => _setFundRounds(ev.target.value)}
                                        InputProps={{
                                            endAdornment: <Button
                                                variant="contained"
                                                disabled={!checkChain()}
                                                onClick={fundRounds}>submit</Button>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Panel>
                        <List disablePadding>
                            <ListItem disablePadding>
                                <ListItemText
                                    primary={`${CoineusCryptoFormat(raffle.totalWinnings)} ${raffle.tokens.jackpot.symbol}`}
                                    primaryTypographyProps={{
                                        style: {fontWeight: 700},
                                        variant: 'h5',
                                        textAlign: 'center'
                                    }}
                                    secondary="Total Winnings All-time"
                                    secondaryTypographyProps={{textAlign: 'center'}}
                                />
                            </ListItem>
                        </List>
                    </Panel>
                </Grid>

                {
                    raffle.ticketCost !== 0 && <Grid item xs={12}>
                        <Panel>
                            {
                                (timeToNextDraw(raffle.nextDraw) > 0) ?
                                    <Typography variant="caption" style={{
                                        float: "left",
                                        padding: '15px 15px 0 15px'
                                    }}>Drawing in {drawingTime}</Typography>
                                    : <Typography variant="caption"
                                                  style={{float: "left", padding: '15px 15px 0 15px'}}>Waiting for
                                        Winner</Typography>
                            }

                            <Typography variant="caption" style={{
                                float: "right",
                                padding: '15px 15px 0 15px'
                            }}>Max Entries: {raffle.entriesAllowed} - Draw# {raffle.raffleID}</Typography>

                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={getOccurrence(raffle.players, wallet.address)}
                                        primaryTypographyProps={{
                                            style: {fontWeight: 700},
                                            variant: 'h5'
                                        }}
                                        secondary="My Entries"/>
                                    <ListItemText
                                        primary={getOdds(raffle.players, wallet.address) + "%"}
                                        primaryTypographyProps={{
                                            style: {fontWeight: 700},
                                            variant: 'h5'
                                        }}
                                        secondary="My Odds"/>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(raffle.jackpot)} ${raffle.tokens.jackpot.symbol}`}
                                        primaryTypographyProps={{
                                            style: {fontWeight: 700},
                                            variant: 'h5',
                                            textAlign: 'right'
                                        }}
                                        secondary={`${raffle.raffleType === 1 || raffle.raffleType === 3 ? '' : 'Current'} Jackpot`}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                    />
                                </ListItem>

                                <ListItem style={{padding: '0 16px'}}>
                                    <Slider
                                        step={raffle.entriesAllowed - getOccurrence(raffle.players, wallet.address) > 10 ? 10 : 1}
                                        marks
                                        min={1}
                                        max={raffle.entriesAllowed - getOccurrence(raffle.players, wallet.address)}
                                        value={numTickets}
                                        onChange={(ev, val) => {
                                            setNumTickets(val)
                                        }}
                                    />
                                </ListItem>

                                <ListItem>
                                    {
                                        checkChain()
                                            ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        disabled={pending || getButtonDisabled()}
                                                        onClick={raffle.tokens.entry.id === "0x0000000000000000000000000000000000000000" ? () => enterNativeMulti(numTickets) : () => enterMulti(numTickets)}
                                                    >
                                                        {getButtonLabel(numTickets)}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={changeNetwork}>CHANGE NETWORK</Button>
                                    }
                                </ListItem>

                                {
                                    (timeToNextDraw(raffle.nextDraw) < 0) && <ListItem>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            disabled={(raffle.raffleType === 1 || raffle.raffleType === 3) &&
                                                (raffle.roundsRemaining - 1) === 0 && wallet.address !== "0xb80267eA7fa368374ee4d4Bf10044778232AdEFe"}
                                            onClick={pickWinner}>PICK WINNER</Button>
                                    </ListItem>
                                }

                                {
                                    (timeToNextDraw(raffle.nextDraw) < 0) &&
                                    (raffle.raffleType === 1 || raffle.raffleType === 3) &&
                                    (raffle.roundsRemaining - 1) === 0 && <ListItem>
                                        <Typography variant="caption">This Raffle has run out of Jackpot Tokens for the next
                                            round to start. Please wait for admin to pick winner.</Typography>
                                    </ListItem>
                                }

                            </List>
                        </Panel>
                    </Grid>
                }


                <Grid item xs={12}>
                    <Panel>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`0X...${raffle.previousWinner.substr(-6)}`}
                                    secondary="Previous Winner"
                                />
                                <ListItemText
                                    primary={`${CoineusCryptoFormat(raffle.previousJackpot / (10 ** raffle.tokens.jackpot.decimals))} ${raffle.tokens.jackpot.symbol}`}
                                    primaryTypographyProps={{textAlign: 'right'}}
                                    secondary="Amount"
                                    secondaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                        </List>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <List>
                            <Typography style={{marginLeft: 15}}>All-time Stats</Typography>
                            <ListItem>
                                <ListItemText
                                    primary={raffle.playerStats.entries}
                                    secondary="My Entries"
                                />
                                <ListItemText
                                    primary={raffle.playerStats.wins}
                                    secondary="My Wins"
                                />
                                <ListItemText
                                    primary={`${CoineusCryptoFormat(raffle.playerStats.winnings / (10 ** raffle.tokens.jackpot.decimals))} ${raffle.tokens.jackpot.symbol}`}
                                    secondary="My Winnings"
                                />
                            </ListItem>
                        </List>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel style={{padding: 15}}>
                        <List dense disablePadding>
                            {
                                raffle.players.length > 0 && <>
                                    <Typography>{raffle.players.length} Entries</Typography>
                                    {getPlayersList(raffle.players, wallet.address)}
                                </>
                            }

                            {
                                raffle.players.length <= 0 && <ListItem>
                                    <ListItemText primary="No Entries"/>
                                </ListItem>
                            }

                        </List>
                    </Panel>
                </Grid>

                {
                    (raffle.raffleType === 1 || raffle.raffleType === 3) && <Grid item xs={12}>
                        <Panel style={{padding: 15}}>
                            <List dense disablePadding>
                                <ListItem>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(raffle.jackpotTokenBalance)} ${raffle.tokens.jackpot.symbol}`}
                                        secondary="Contract Balance"
                                    />
                                    <ListItemText
                                        primary={parseInt(raffle.roundsRemaining)}
                                        secondary="Rounds Funded"
                                    />
                                </ListItem>
                            </List>
                        </Panel>
                    </Grid>
                }
            </Grid>
            <CoineusLoading open={pending} label="Transaction Pending"/>
        </Container>
    );
}

function timeToNextDraw(d) {
    return d - new Date();
}

function msToHMS(ms) {
    let h = ms / 3.6e6 | 0;
    let m = (ms % 3.6e6) / 6e4 | 0;
    let s = (ms % 6e4) / 1e3 | 0;
    return `${h}:${('' + m).padStart(2, '0')}:${('' + s).padStart(2, '0')}`;
}

function getOccurrence(array, value) {
    return array.filter((v) => (v === value)).length;
}

function getOdds(array, value) {
    if (array.length === 0) return 0;

    return ((getOccurrence(array, value) / array.length) * 100).toFixed(0);
}

function getPlayersList(players, wallet) {

    let list = [];
    let currentPlayer, nextPlayer, found = 1;

    players.map((player, i) => {
        currentPlayer = player;
        nextPlayer = players[i + 1];


        if (currentPlayer === nextPlayer) {
            found++;
        } else {

            list.push(<ListItem key={player + "" + i} disablePadding>
                <ListItemText
                    primary={`0x..${player.substr(-6)} ${found > 1 ? found + "x" : ""}`}
                    primaryTypographyProps={{
                        color: player.toLowerCase() === wallet.toLowerCase() ? "secondary" : "",
                        variant: "caption",
                        fontFamily: 'monospace'
                    }}
                />
            </ListItem>);

            found = 1;
        }
        return player;
    });
    return list;
}