import * as React from "react";
import {ArcSeries, FlexibleWidthXYPlot} from "react-vis";
import {Paper, Typography, ListItem, ListItemIcon, ListItemText, Skeleton, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    Autorenew,
    Whatshot
} from "@mui/icons-material";
import {CoineusCryptoFormat} from "../utils/currency_format";

const useStyles = makeStyles((theme) => ({
    xyplot: {
        margin: '0 auto',
    },
}));

function BurnSupply(props) {
    const classes = useStyles();

    const {supply, burned} = props;

    const divider = 360 * .0075 * (Math.PI / 180);

    const aBurned = 360 * (burned/supply) * (Math.PI / 180);

    const angles = {

        burnedStart: 0,
        burnedEnd: aBurned,

        supplyStart:  aBurned + divider,
        supplyEnd: 360 * (Math.PI / 180) - divider,
    }


    const colors = {
        supply: '#DADADA',
        burned: '#fa5700'
    }

    if (
        supply === undefined
    ) {
        return <Paper style={{padding: 20}}>
            <Skeleton variant="circular" height={180} width={180} style={{margin: '0 auto'}}/>
        </Paper>;
    }

    return (
        <Paper style={{padding: 20}}>
            <FlexibleWidthXYPlot
                xDomain={[-5, 5]}
                yDomain={[-5, 5]}
                height={250}
                margin={{left: 0, right: 0, top: 0, bottom: 0}}
                className={classes.xyplot}
            >
                <ArcSeries
                    radiusDomain={[0, 10]}
                    center={{x: 0, y: 0}}
                    data={[
                        {
                            angle0: angles.supplyStart,
                            angle: angles.supplyEnd,
                            opacity: 1,
                            radius: 6.75,
                            radius0: 6,
                            color: colors.supply,
                        },
                        {
                            angle0: angles.burnedStart,
                            angle: angles.burnedEnd,
                            opacity: 1,
                            radius: 6.75,
                            radius0: 6,
                            color: colors.burned,
                        }
                    ]}
                    colorType={'literal'}
                />
            </FlexibleWidthXYPlot>

            <Typography
                style={{
                    height: 0,
                    width: '100%',
                    textAlign: 'center',
                    top: -155,
                    position: 'relative',
                    fontSize: 30,
                    fontWeight: '700'
                }}
            >
                {((burned/supply) * 100).toFixed(2)}
                <span
                    style={{
                        fontSize: 12,
                        fontWeight: '400',
                        marginTop: -10,
                        display: 'block',
                    }}
                >
          % BURNED
        </span>
            </Typography>

            <Grid container>

                <Grid item xs={6}>
                    <StatListItem icon={<Autorenew style={{color: colors.supply}}/>} color={colors.supply}
                                  value={supply}
                                  label="SUPPLY"/>
                </Grid>
                <Grid item xs={6}>
                    <StatListItem icon={<Whatshot style={{color: colors.burned}}/>} color={colors.burned}
                                  value={burned}
                                  label="BURNED"/>
                </Grid>

                <Grid item xs={6}>
                    <StatListItem icon={<Autorenew style={{color: colors.supply}}/>} color={colors.supply}
                                  value={supply - burned}
                                  label="CIRCULATION"/>
                </Grid>
            </Grid>

        </Paper>
    );
}

export default BurnSupply;

function StatListItem(props) {
    const {color, value, label, icon} = props;

    return <ListItem disablePadding>
        <ListItemIcon style={{paddingLeft: 15}}>
            {icon}
        </ListItemIcon>
        <ListItemText
            primary={CoineusCryptoFormat(value)}
            secondary={label}
            primaryTypographyProps={{fontWeight: 700, style: {color}}}
            secondaryTypographyProps={{fontSize: '.75rem', style: {color}}}
        />
    </ListItem>;
}
