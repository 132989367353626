import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import Web3 from "web3";

import {
    Avatar,
    Button,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper, Skeleton, Tab, Tabs,
    ToggleButton,
    ToggleButtonGroup, Typography
} from "@mui/material";

import {makeBatchRequest} from "../../utils/promisify";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../../utils/currency_format";

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import BoltIcon from '@mui/icons-material/Bolt';

import IERC20 from "../../abis/IERC20.json";
import NATIVE_NATIVE_ABI from "../../abis/LightningRaffles/NativeNative.json";
import NATIVE_TOKEN_JACKPOT_ABI from "../../abis/LightningRaffles/NativeTokenJackpot.json";
import TOKEN_TOKEN_ABI from "../../abis/LightningRaffles/TokenToken.json";
import TOKEN_TOKEN_JACKPOT_ABI from "../../abis/LightningRaffles/TokenTokenJackpot.json";
import Panel from "../../components/Panel";

import {useNetworkChip} from "../../theme";
import clsx from "clsx";

export default function LightningRaffles() {

    const [displayUSD, setDisplayUSD] = useState(false);
    const [chainTab, setChainTab] = useState('fuse');

    return (
        <Grid container spacing={2} style={{paddingBottom: 60}}>
            <Grid item xs={12} style={{textAlign: 'right'}}>
                <ToggleButtonGroup
                    value={displayUSD}
                    exclusive
                    onChange={() => setDisplayUSD(!displayUSD)}
                    style={{backgroundColor: '#000'}}
                >
                    <ToggleButton value={false}>
                        <CurrencyBitcoinIcon/>
                    </ToggleButton>
                    <ToggleButton value={true}>
                        <AttachMoneyIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'right'}}>
                <Tabs
                    variant="fullWidth"
                    value={chainTab}
                    onChange={(ev, val) => {
                        setChainTab(val)
                    }}
                >
                    <Tab id="fuse" value="fuse" label="FUSE"/>
                    <Tab id="bnb" value="bnb" label="BNB"/>
                </Tabs>
            </Grid>
            {
                chainTab === 'fuse' && <>

                    <RaffleGameInfo
                        contract_address='0x6AC221FB606237f3941F04Dc7053c4e8e93C9c4E'
                        abi={NATIVE_NATIVE_ABI}
                        displayUSD={displayUSD}
                        network="fuse"
                    />

                    <RaffleGameInfo
                        contract_address='0xE52aE739b312067CA85487aF5536808dB243d1d1'
                        abi={NATIVE_TOKEN_JACKPOT_ABI}
                        displayUSD={displayUSD}
                        network="fuse"
                    />

                    <RaffleGameInfo
                        contract_address='0x774A28f03999761F0d902764C711F48073622fAf'
                        abi={TOKEN_TOKEN_ABI}
                        displayUSD={displayUSD}
                        network="fuse"
                    />

                    <RaffleGameInfo
                        contract_address='0x4abB7BBb1C9879EF461383f8B76A786B4AfC1fb7'
                        abi={NATIVE_TOKEN_JACKPOT_ABI}
                        displayUSD={displayUSD}
                        network="fuse"
                    />

                    <RaffleGameInfo
                        contract_address='0xC85ddCc2f98EBFA169085771E6300C8e1b500eE0'
                        abi={TOKEN_TOKEN_JACKPOT_ABI}
                        displayUSD={displayUSD}
                        network="fuse"
                    />
                </>
            }


            {
                chainTab === 'bnb' && <Grid item xs={12}>

                    <RaffleGameInfo
                        contract_address='0xb23E8FE3EA397e78b0cCe913c84775c67Bdc9282'
                        abi={TOKEN_TOKEN_JACKPOT_ABI}
                        displayUSD={displayUSD}
                        network="bnb"
                    />

                </Grid>
            }


        </Grid>
    );
}

function RaffleGameInfo(props) {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const {contract_address, abi, network, displayUSD} = props;

    const [raffle, setRaffle] = useState({nextDraw: 0});

    const classes = useNetworkChip();

    const getRaffleGameInfo = () => {

        const web3 = new Web3(rpc[network]);

        const raffle_contract = new web3.eth.Contract(abi, contract_address);

        const calls = [
            raffle_contract.methods.getRaffleInfo().call,
            raffle_contract.methods.getTokens().call,
            raffle_contract.methods.getPlayers().call,
            web3.eth.getBalance(contract_address)
        ];

        makeBatchRequest(web3, calls).then(resp => {
            const [
                raffleInfo,
                tokens,
                players,
                bal
            ] = resp;

            let jp;
            let token_contract = new web3.eth.Contract(IERC20, tokens.jackpot.id);

            //raffleType enum
            //{NATIVE_NATIVE, NATIVE_TOKEN_JACKPOT, TOKEN_TOKEN, TOKEN_TOKEN_JACKPOT}
            // eslint-disable-next-line default-case
            switch (parseInt(raffleInfo.raffle_type)) {
                case 0:
                    //use native bal
                    jp = bal - (bal / raffleInfo.admin_fee);
                    break;
                case 1:
                case 3:
                    //use cap
                    jp = raffleInfo.cap;
                    break;
                case 2:
                    jp = undefined;
                    break;
            }

            /**
             * need to call this just in the case of TOKEN_TOKEN_JACKPOT
             * have to check if jackpot token is 0x00, cause if native token will error trying to call contract.method
             */
            makeBatchRequest(web3, [tokens.jackpot.id === "0x0000000000000000000000000000000000000000" ? web3.eth.getBalance(contract_address) : token_contract.methods.balanceOf(contract_address).call]).then(resp => {

                /**
                 * if jp (jackpot) wasn't set before, it means that the resp is needed
                 */
                if (jp === undefined) jp = (resp - (resp / raffleInfo.admin_fee));

                setRaffle({
                    ticketCost: raffleInfo.ticket_cost / (10 ** tokens.entry.decimals),
                    adminFee: raffleInfo.admin_fee / 1,
                    raffleID: raffleInfo.raffle_id / 1,
                    entriesAllowed: raffleInfo.entries_allowed / 1,
                    nextDraw: raffleInfo.next_draw * 1000,
                    nextDrawInterval: raffleInfo.next_draw_interval_hours / 1,
                    totalFees: raffleInfo.total_fees / 1,
                    totalWinnings: raffleInfo.total_winnings / 1,
                    raffleType: raffleInfo.raffle_type / 1,
                    raffleState: raffleInfo.raffle_state / 1,
                    tokens: {entry: tokens.entry, jackpot: tokens.jackpot},
                    jackpot: jp / (10 ** tokens.jackpot.decimals),
                    players
                });
            })
        })
    }

    useEffect(() => {
        getRaffleGameInfo();
        const interval = setInterval(getRaffleGameInfo, 10000)
        return () => clearInterval(interval);
    }, []);

    const [drawingTime, setDrawingTime] = useState(undefined);
    useEffect(() => {
        setDrawingTime(msToHMS(timeToNextDraw(raffle.nextDraw)));
        const interval1 = setInterval(() => {
            setDrawingTime(msToHMS(timeToNextDraw(raffle.nextDraw)));
        }, 1000);
        return () => clearInterval(interval1);
    }, [raffle]);

    if (Object.entries(raffle).length === 1) {
        return <RaffleDetailsLoading/>;
    }

    return <Grid item xs={12} style={{margin: 10}}>
        <Panel>
            <List disablePadding>
                <ListItem disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Paper style={{padding: 20}}>
                                <ListItemAvatar>
                                    <Avatar style={{margin: '0 auto'}}
                                            src={`https://coineus.app/assets/tokens/${network}/${raffle.tokens.entry.id === "0x0000000000000000000000000000000000000000" ? '' : raffle.tokens.entry.id + '/'}logo.png`}/>
                                    {raffle.tokens.entry.id !== "0x0000000000000000000000000000000000000000" &&
                                        <span className={clsx(classes.networkChip, network)}
                                              style={{marginLeft: 10, top: 52}}/>}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={displayUSD ? `~${CoineusUSDFormat(raffle.ticketCost * (wallet.prices[network][raffle.tokens.entry.id === "0x0000000000000000000000000000000000000000" ? "native" : raffle.tokens.entry.id]?.inUSD || 0))}` : `${CoineusCryptoFormat(raffle.ticketCost)} ${raffle.tokens.entry.symbol}`}
                                    primaryTypographyProps={{
                                        style: {fontWeight: 700}
                                    }}
                                    secondary={`Ticket Price`}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Paper style={{padding: 20}}>
                                <ListItemAvatar>
                                    <Avatar style={{margin: '0 auto'}}
                                            src={`https://coineus.app/assets/tokens/${network}/${raffle.tokens.jackpot.id === "0x0000000000000000000000000000000000000000" ? '' : raffle.tokens.jackpot.id + '/'}logo.png`}/>
                                    {raffle.tokens.jackpot.id !== "0x0000000000000000000000000000000000000000" &&
                                        <span className={clsx(classes.networkChip, network)}
                                              style={{marginLeft: 10, top: 52}}/>}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={displayUSD ? `~${CoineusUSDFormat(raffle.jackpot * (wallet.prices[network][raffle.tokens.jackpot.id === "0x0000000000000000000000000000000000000000" ? "native" : raffle.tokens.jackpot.id]?.inUSD || 0))}` : `${CoineusCryptoFormat(raffle.jackpot)} ${raffle.tokens.jackpot.symbol}`}
                                    primaryTypographyProps={{
                                        style: {fontWeight: 700}
                                    }}
                                    secondary={`${raffle.raffleType === 1 || raffle.raffleType === 3 ? '' : 'Current'} Jackpot`}
                                />
                            </Paper>
                        </Grid>
                    </Grid>

                </ListItem>

                <ListItem disableGutters>

                    <RouterLink to={`/raffles/${network}/${contract_address}`}
                                style={{width: '100%', textDecoration: 'none'}}>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={raffle.raffleState === 1 && wallet.address !== '0xb80267eA7fa368374ee4d4Bf10044778232AdEFe'}
                            endIcon={getOccurrence(raffle.players, wallet.address) ? <BoltIcon/> : undefined}
                        >{raffle.raffleState === 1 ? "RAFFLE CLOSED" : (timeToNextDraw(raffle.nextDraw) > 0) ? `Drawing in ${drawingTime}` : `Waiting for Winner`}
                        </Button>

                    </RouterLink>
                </ListItem>
            </List>
        </Panel>
    </Grid>
}

function RaffleDetailsLoading() {
    return (
        <Grid item xs={12} style={{margin: 10}}>
            <Panel style={{padding: 15}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Skeleton variant="rounded" height={160} width="100%"/>
                    </Grid>
                    <Grid item xs={6}>
                        <Skeleton variant="rounded" height={160} width="100%"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton variant="rounded" height={36} width="100%"/>
                    </Grid>
                </Grid>
            </Panel>
        </Grid>);
}

function timeToNextDraw(d) {
    return d - new Date();
}

function msToHMS(ms) {
    let h = ms / 3.6e6 | 0;
    let m = (ms % 3.6e6) / 6e4 | 0;
    let s = (ms % 6e4) / 1e3 | 0;
    return `${h}:${('' + m).padStart(2, '0')}:${('' + s).padStart(2, '0')}`;
}

function getOccurrence(array, value) {
    return array.filter((v) => (v === value)).length;
}
