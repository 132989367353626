import store from "../redux/store";
import Web3 from "web3";
import unipair_abi from "../abis/IUniswapV2Pair.json";
import srg_abi from "../abis/srg_abi.json";
import bbk_abi from "../abis/bbk_abi.json";

import {makeBatchRequest} from "./promisify";
import {updatePrices, updateTotals} from "../redux/slices/coineusSlice";

export default function getPairPrices() {
    const _store = store.getState();
    const {pairs, rpc} = _store.coineus;

    getFUSE(pairs, rpc.fuse);
    getAVAX(pairs, rpc.avax);
    getBNB(pairs, rpc.bnb);
    getETH(pairs, rpc.eth);
}

function getFUSE(pairs, rpc) {

    //FUSE
    const web3 = new Web3(rpc);
    const fusd_fuse = new web3.eth.Contract(unipair_abi, pairs.fUSDFUSEpair);
    const ceus_fuse = new web3.eth.Contract(unipair_abi, pairs.CEUSFUSEpair);
    const ceus_lit = new web3.eth.Contract(unipair_abi, pairs.CEUSLITPair);

    const calls = [
        fusd_fuse.methods.getReserves().call,
        ceus_fuse.methods.getReserves().call,
        ceus_lit.methods.getReserves().call,
    ]

    makeBatchRequest(web3, calls)
        .then(resp => {
            const [
                fusd_fuse_reserves,
                ceus_fuse_reserves,
                ceus_lit_reserves,
            ] = resp

            //FUSE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: 'native',
                price: {
                    inUSD: fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inFuse: 1
                }
            }));

            //WFUSE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',
                price: {
                    inUSD: fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inFuse: 1
                }
            }));

            //FUSD
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xd0ce1b4A349C35e61Af02f5971e71ac502441E49',
                price: {
                    inUSD: 1,
                    inFuse: fusd_fuse_reserves.reserve0/fusd_fuse_reserves.reserve1
                }
            }));

            //CEUS
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0
                }
            }));

            //CEUSONE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xE27431FACb1F093BA164c9F86B3f484b39ad54CC',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0
                }
            }));

            //LIT
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_lit_reserves.reserve0/ceus_lit_reserves.reserve1 * (ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0)
                }
            }));

            store.dispatch(updateTotals('fuse'));
        });
}

function getAVAX(pairs, rpc) {


    //AVAX
    const web3AVAX = new Web3(rpc);
    const usdc_avax = new web3AVAX.eth.Contract(unipair_abi, pairs.USDCAVAXpair);
    const dgnx_avax = new web3AVAX.eth.Contract(unipair_abi, pairs.DGNXAVAXpair);

    const callsAVAX = [
        usdc_avax.methods.getReserves().call,
        dgnx_avax.methods.getReserves().call,
    ]

    makeBatchRequest(web3AVAX, callsAVAX)
        .then(resp => {
            const [
                usdc_avax_reserves,
                dgnx_avax_reserves,
            ] = resp

            const avax_price = usdc_avax_reserves.reserve1/usdc_avax_reserves.reserve0 * (10**12)

            //AVAX
            store.dispatch(updatePrices({
                chain: 'avax',
                contract_address: 'native',
                price: {
                    inUSD: avax_price,
                    inAVAX: 1
                }
            }));

            //USDC
            store.dispatch(updatePrices({
                chain: 'avax',
                contract_address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
                price: {
                    inUSD: 1,
                    inAVAX: usdc_avax_reserves.reserve0/usdc_avax_reserves.reserve1 / (10**12)
                }
            }));

            //DGNX
            store.dispatch(updatePrices({
                chain: 'avax',
                contract_address: '0x51e48670098173025C477D9AA3f0efF7BF9f7812',
                price: {
                    inAVAX: dgnx_avax_reserves.reserve1/dgnx_avax_reserves.reserve0 || 0,
                    inUSD: dgnx_avax_reserves.reserve1/dgnx_avax_reserves.reserve0 * avax_price || 0
                }
            }));

            store.dispatch(updateTotals('avax'));

        });
}

function getBNB(pairs, rpc) {

    //BSC
    const web3BSC = new Web3(rpc);
    const bnb_busd = new web3BSC.eth.Contract(unipair_abi, pairs.BNBBUSDpair);
    const feg_bnb = new web3BSC.eth.Contract(unipair_abi, pairs.BNBFEGPair);
    const srg_bnb = new web3BSC.eth.Contract(srg_abi, '0x9f19c8e321bD14345b797d43E01f0eED030F5Bff');
    const lit_bnb = new web3BSC.eth.Contract(srg_abi, '0xbC8F89861737C3B8c60a05165E0a5C5B8A137fAc');

    const callsBNB = [
        bnb_busd.methods.getReserves().call,
        feg_bnb.methods.getReserves().call,
        srg_bnb.methods.calculatePrice().call,
        lit_bnb.methods.calculatePrice().call,
    ]

    makeBatchRequest(web3BSC, callsBNB)
        .then(resp => {
            const [
                bnb_busd_reserves,
                feg_bnb_reserves,
                srg_bnb_price,
                lit_bnb_price,
            ] = resp

            const bnb_price = bnb_busd_reserves.reserve0/bnb_busd_reserves.reserve1

            //BNB
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: 'native',
                price: {
                    inUSD: bnb_price,
                    inBNB: 1
                }
            }));

            //SRG
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x9f19c8e321bD14345b797d43E01f0eED030F5Bff',
                price: {
                    inUSD: (srg_bnb_price/(10**9)) * bnb_price,
                    inETH: (srg_bnb_price/(10**9))
                }
            }));

            //SRGONE
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x14F91F92aF274DDFF7f1A7f187B5eB5385F5f772',
                price: {
                    inUSD: (srg_bnb_price/(10**9)) * bnb_price,
                    inETH: (srg_bnb_price/(10**9))
                }
            }));

            //LIT
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0xbC8F89861737C3B8c60a05165E0a5C5B8A137fAc',
                price: {
                    inUSD: (lit_bnb_price/(10**9)) * (srg_bnb_price/(10**18)) * bnb_price,
                    inETH: (lit_bnb_price/(10**9))
                }
            }));

            //FEG
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11',
                price: {
                    inUSD: (feg_bnb_reserves.reserve0/feg_bnb_reserves.reserve1) * bnb_price,
                    inBNB: 1
                }
            }));

            //BUSD
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
                price: {
                    inUSD: 1
                }
            }));

            //USDC
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
                price: {
                    inUSD: 1
                }
            }));

            //USDT
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x55d398326f99059fF775485246999027B3197955',
                price: {
                    inUSD: 1
                }
            }));

            store.dispatch(updateTotals('bnb'));
        });

}

function getETH(pairs, rpc) {


    //ETH
    const web3ETH = new Web3(rpc);
    const eth_usdc = new web3ETH.eth.Contract(unipair_abi, pairs.ETHUSDCPair);
    const srg_eth = new web3ETH.eth.Contract(srg_abi, pairs.ETHSRGPair);
    const bbk_eth = new web3ETH.eth.Contract(bbk_abi, '0xF654d4C3CC334324ad474A0d5d3708dCA4c1CB25');
    const feg_eth = new web3ETH.eth.Contract(unipair_abi, pairs.ETHFEGPair);

    const callsETH = [
        eth_usdc.methods.getReserves().call,
        srg_eth.methods.calculatePrice().call,
        bbk_eth.methods.calculatePrice().call,
        feg_eth.methods.getReserves().call
    ]

    makeBatchRequest(web3ETH, callsETH)
        .then(resp => {

            const [
                eth_usdc_reserves,
                srg_eth_price,
                bbk_srg_price,
                feg_eth_reserves
            ] = resp

            const eth_price = eth_usdc_reserves.reserve0/eth_usdc_reserves.reserve1*(10**12)

            //ETH
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: 'native',
                price: {
                    inUSD: eth_price,
                    inETH: 1
                }
            }));

            //SRG
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xcD682EF09d07668d49A8103ddD65Ff54AebFbfDe',
                price: {
                    inUSD: (srg_eth_price/(10**9)) * eth_price,
                    inETH: (srg_eth_price/(10**9))
                }
            }));

            //BBK
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xF654d4C3CC334324ad474A0d5d3708dCA4c1CB25',
                price: {
                    inUSD: (bbk_srg_price/(10**18)) * (srg_eth_price/(10**9)) * eth_price,
                    inETH: 1
                }
            }));

            //FEG
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11',
                price: {
                    inUSD: (feg_eth_reserves.reserve1/feg_eth_reserves.reserve0) * eth_price,
                    inETH: 1
                }
            }));

            //USDC
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                price: {
                    inUSD: 1
                }
            }));

            //USDT
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
                price: {
                    inUSD: 1
                }
            }));

            store.dispatch(updateTotals('eth'));

        })
        .catch(console.log);

}

