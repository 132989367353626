import {createTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";


const theme = createTheme({
    typography: {
        fontFamily: `'Quicksand', sans-serif`
    },
    palette: {
        mode: 'dark',
        background: {
            default: "#000"
        },
        primary: {
            main: '#DADADA'
        },
        secondary: {
            main: '#a9a9a9'
        }
    }
});

const useNetworkChip = makeStyles((theme) => ({
    networkChip: {
        borderRadius: "50%",
        marginLeft: 30,
        position: 'absolute',
        top: 42,
        height: 16,
        width: 16,
        display: 'inline-block',
        backgroundSize: 'cover',
        '&.fuse': {
            backgroundImage: `url('https://coineus.app/assets/tokens/fuse/logo.png')`,
        },
        '&.avax': {
            backgroundImage: `url('https://coineus.app/assets/tokens/avax/logo.png')`,
        },
        '&.bnb': {
            backgroundImage: `url('https://coineus.app/assets/tokens/bnb/logo.png')`,
        },
        '&.eth': {
            backgroundImage: `url('https://coineus.app/assets/tokens/eth/logo.png')`,
        }
    }
}));

export {useNetworkChip};

export default theme;